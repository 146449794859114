import React, { useEffect, useState } from "react"
import sanitizeHtml from 'sanitize-html';
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { normalizePath } from "../../utils/get-url-path";
import { OptionList } from "./options-list";
import sanitizeOptions from "../../helper/sanitizeOptions";
import Img from "gatsby-image";
import * as constants from "../../helper/constants";


export const Item = ({ setVisible, showNumberItems, i, page, startAnimation }) => {
    const sanitizeOpt = sanitizeOptions();
    const [windowSize, setWindowSize] = useState(false);

    useEffect(() => {
        (window.innerWidth < constants.MOBILE_WIDTH) ? setWindowSize(false) : setWindowSize(true);
    }, []);


    return (
        <AniLink
            className={'case-study-item flex-item visible animated'}
            style={(setVisible(showNumberItems, i))}
            data-animate="animate-container"
            key={page.id}
            paintDrip
            duration={1}
            hex="#ed5d2b"
            to={normalizePath(page.link)}
        >
            <div
                className={'case-study-link'}>
                <div className={'featured-image'}>
                  {!!page.featuredImage?.node?.remoteFile?.childImageSharp && (
                    <Img fluid={page.featuredImage.node.remoteFile.childImageSharp.fluid}/>
                  )}
                </div>
                <div className={'flex-item case-study-title'}>
                    <h4 className={'h4-title major-title black'}
                        data-animated-group="content">
                        {page.title}
                    </h4>
                    {!!page?.caseStudyTechnologies?.nodes.length && (
                      <div className={'paragraph-secondary minor-title black'}
                           data-animated-group="content">
                        <OptionList data={page.caseStudyTechnologies}
                                    className={'black'}/>
                      </div>
                    )}
                </div>
                <div className={'flex-item case-study-description paragraph-primary black'}
                     data-animated-group="content">
                    {page?.info?.aboutClientShort && (
                        <p dangerouslySetInnerHTML={{ __html: sanitizeHtml(page.info.aboutClientShort, sanitizeOpt.title) }}/>
                    )}
                </div>
            </div>
        </AniLink>
    )
}
