import React, {useEffect, useState} from "react";
import sanitizeHtml from 'sanitize-html';
import AniLink from "gatsby-plugin-transition-link/AniLink"
import {normalizePath} from "../../utils/get-url-path";
import Img from "gatsby-image";
import sanitizeOptions from "../../helper/sanitizeOptions";
import disableAnimationForMobile from '../../helper/disableAnimationForMobile';

import "../style.scss"
import * as constants from "../../helper/constants";

const PostsList = ({isVisible, data}) => {
    const sanitizeOpt = sanitizeOptions();
    const [startAnimation, setStartAnimation] = useState(false);
    const [animateLines, setAnimateLines] = useState(false);
    const [windowSize, setWindowSize] = useState(false);

    useEffect(() => {
        setWindowSize(window.innerWidth < constants.MOBILE_WIDTH);
        // Disable animation for mobile
        let setIsVisible = disableAnimationForMobile(isVisible);

        if (setIsVisible) {
            window.setTimeout(() => {
                setStartAnimation(true);
            }, 150);

            window.setTimeout(() => {
                setAnimateLines(true);
            }, 650)
        }
    }, [isVisible]);

    return (
        <ul className={'blog-list flex-col'}>
            {!!data?.nodes && data.nodes.map((page, i) => (
                <li className={'flex-item full-width blog-item min-height'}
                    key={page.id}>
                    <AniLink
                        className={'blog-item-link'}
                        paintDrip
                        duration={1}
                        hex="#ed5d2b"
                        to={normalizePath(page?.uri)}>
                        <div
                            className={'post-image full-width'}
                            style={{
                                overflow: 'hidden'
                            }}
                        >
                            {(i < 1) && !!page?.featuredImage?.node?.remoteFile?.childImageSharp && (
                                <Img style={{
                                    transition: !windowSize ? 'all .75s ease' : '',
                                    transform: `translateX(${startAnimation ? '0' : '-100%'})`,
                                }}
                                     fluid={
                                         page.featuredImage.node.remoteFile.childImageSharp.fluid
                                     }
                                />
                            )}
                        </div>
                        <hr
                            className="hr post-hr"
                            style={{
                                transition: !windowSize ? 'all .75s ease' : '',
                                width: `${animateLines ? '100%' : '0'}`,
                            }}
                        />
                        <h4
                            className={'post-title h4-title major-title black'}
                            style={{
                                transition: !windowSize ? 'all .75s ease' : '',
                                opacity: `${animateLines ? 1 : 0}`,
                            }}
                        >
                            <span>{page?.title}</span>
                        </h4>
                        <div
                            className={'post-info paragraph-secondary gray'}
                            style={{
                                transition: !windowSize ? 'all .75s ease' : '',
                                opacity: `${animateLines ? 1 : 0}`,
                            }}
                        >
                            <span className={'post-time'}>{page?.date}</span>
                            <span className={'post-author'}>{page?.author?.node?.name}</span>
                        </div>
                        {page?.postFields?.homeText && (
                            <div
                                className={'post-content paragraph-primary black'}
                                style={{
                                    transition: !windowSize ? 'all .75s ease' : '',
                                    opacity: `${animateLines ? 1 : 0}`,
                                }}
                            >
                                <span
                                    dangerouslySetInnerHTML={{__html: sanitizeHtml(page.postFields.homeText, sanitizeOpt.title)}}/>
                            </div>
                        )}
                    </AniLink>
                    {(i === 2) && windowSize && <hr
                        className="hr post-hr last"
                        style={{
                            transition: !windowSize ? 'all .75s ease' : '',
                            width: `${animateLines ? '100%' : '0'}`,
                        }}
                    />}
                </li>
            ))}
        </ul>
    )
}

export default PostsList

