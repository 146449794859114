import React, {useEffect, useState} from "react";
import sanitizeHtml from 'sanitize-html';
import sanitizeOptions from "../../helper/sanitizeOptions";
import disableAnimationForMobile from "../../helper/disableAnimationForMobile";

const ServiceCategoriesTitle = ({isVisible, title}) => {
    const sanitizeOpt = sanitizeOptions();
    const [startAnimation, setStartAnimation] = useState(false);

    useEffect(() => {
        // Disable animation for mobile
        let setIsVisible = disableAnimationForMobile(isVisible);
        if (setIsVisible) {
            setStartAnimation(true);
        }
    }, [isVisible]);

    return (
        <div
            style={{
                width: '100%',
                overflow: 'hidden'
            }}
        >
            <h2
                className={'h2-title'}
                dangerouslySetInnerHTML={{__html: sanitizeHtml(title, sanitizeOpt.title)}}
                style={{
                    transition: 'all .75s ease',
                    transform: `translateY(${startAnimation ? 0 : '-110%'})`,
                }}
            />
        </div>
    )
}

export default ServiceCategoriesTitle;