import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {normalizePath} from "../../utils/get-url-path";
import CaseStudyGrid from "../case-study-grid";
import PostsList from "../posts-list";
import Subscribe from "../subscribe";
import TrackVisibility from 'react-on-screen';
import TitleBlock from "./TitleBlock";

const getComponents = (caseStudies, homepageCustomFields, customFields) => {
    return (
        (customFields === 'blog_block') ?
            <TrackVisibility once offset={100} className={'posts-list-wrapper'}>
                <PostsList data={caseStudies}/>
            </TrackVisibility> :
            (customFields === 'case_studies_block') ?
                <TrackVisibility once offset={700} className={'case-study-grid-wrapper'}>
                    <CaseStudyGrid data={caseStudies}/>
                </TrackVisibility> :
                null
    )
};

const TitleGrids = ({data}) => {
    const {
        titleH2,
        titleH5,
        description,
        button,
        link,
        customFields,
        caseStudies,
        homepageCustomFields
    } = data;

    return (
        <>
            <div className={'case-studies-title visible animated'}>
                <div className={'left-container flex-container'}>
                    <TrackVisibility once offset={50}>
                        <TitleBlock
                            titleH2={titleH2}
                            titleH5={titleH5}
                        />
                    </TrackVisibility>
                </div>
                <div className={'right-container 5'}
                     data-animated-group="animated-content">
                    {(customFields === 'case_studies_block') && (
                        <p className={'paragraph-primary__bold'}>{description}</p>
                    )}
                    {!!link && !!button && (
                        <AniLink to={normalizePath(link)}
                                 paintDrip
                                 duration={1}
                                 hex="#ed5d2b"
                                 className={'transition-link paragraph-primary__bold possibilities-link mc-link mc-button-secondary right'}>
                            {button}</AniLink>
                    )}
                </div>
                {getComponents(caseStudies, homepageCustomFields, customFields)}
                {(customFields === 'blog_block') && (
                  <Subscribe/>
                )}
            </div>

        </>
    )
}

export default TitleGrids

