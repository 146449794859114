import React from 'react';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {normalizePath} from "../../utils/get-url-path";

const ServiceSubCategoriesItems = ({element}) => {
    return (
        <li>
            <AniLink
                paintDrip
                duration={1}
                hex="#ed5d2b"
                to={normalizePath(element.uri)}>
                {element.title}
            </AniLink>
        </li>
    )
}

export default ServiceSubCategoriesItems;