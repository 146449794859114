import React, {useState, useEffect} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {useForm} from "react-hook-form";
import {subscribeData} from "../../queries";
import {sendEmail} from "../../Mutations";
import SuccessSubscribeMessage from "./success-message";
import Recaptcha from "react-recaptcha";
import * as constants from "../../helper/constants";
import TrackVisibility from 'react-on-screen';
import SetCaptcha from '../setCaptcha';

const Subscribe = ({typePage, formWrap}) => {
    const {data} = useQuery(subscribeData);
    const formId = data?.form?.id;
    const {register, handleSubmit, errors, formState, reset} = useForm({
        mode: "onSubmit",
    });
    const [
        sendEmailAction,
        {data: emailData}
    ] = useMutation(sendEmail);

    const [eventPopup, seteventPopup] = useState(true);
    const [loader, seteventLoader] = useState(false);
    const [responsiveAction, setResponsiveAction] = useState([]);
    const handleEventPopup = () => {
        seteventPopup(!eventPopup);
        seteventLoader(false);
        reset();
    };

    const onSubmit = (data) => {
        const response = sendEmailAction({
            variables: {
                form_data: `{"your-email":"${data.email}", "skip_spam_check":"true", "mc4wp-subscribe":"1"}`,
                id: formId,
                formId
            }
        });

        response.then(res => {
            reset();
            setResponsiveAction(res.data);
        }).catch(console.error);
        seteventPopup(true);
        seteventLoader(true);
    };

    let recaptchaInstance;

    const resetRecaptcha = (e) => {
        e.preventDefault();
        recaptchaInstance.reset();
        recaptchaInstance.execute();
    };

    const callback = function () {
        console.log('Captcha subscribe. Loaded.');
    };

    const [showCaptcha, setShowCaptcha] = useState(false);

    useEffect(() => {
        window.setTimeout(() => {
            setShowCaptcha(true)
        }, 700);

    }, []);

    return (
        <>
            <TrackVisibility once offset={900}>
                {({isVisible}) => isVisible && <SetCaptcha/>}
            </TrackVisibility>
            <div
                className={`form-subscribe-container orange-background full-width ${typePage ? typePage : ''} ${eventPopup ? 'open' : 'close'}`}>
                <SuccessSubscribeMessage
                    status={responsiveAction?.submitForm?.status}
                    handleEventPopup={handleEventPopup}
                    message={responsiveAction?.submitForm?.message}
                    eventPopup={eventPopup}
                    loader={loader}
                />
                <h4 className={'form-header h4-title black white'}>
                    <span className={'header-item black'}>Subscribe</span>
                    <span className={'header-item'}> to our newsletter</span>
                </h4>
                <div className={'subscribe-text'}>
                    Don't miss out on the latest eCommerce development trends,
                    frameworks, and optimization techniques
                </div>
                {data ? <form
                    id={formId}
                    className={`form newsletter-form ${formWrap ? formWrap : ''}`}>
                    <div className={`input-wrapper${(errors.email ? ' invalid' : '')}`}>
                        <input
                            ref={register({
                                required: {
                                    value: true,
                                    message: 'Please specify a valid email address.'
                                },
                                pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Please provide correct email.'
                                },
                            })}
                            name={'email'}
                            type="email"
                            placeholder={'Email'}
                        />
                        {errors.email?.message &&
                        <div className={'error-badge'}>
                            <i className={'mc-icon mc-icon--caution'}/>
                        </div>}
                        {errors.email?.message &&
                        <div className={'error-message'}>
                            {errors.email?.message}
                        </div>}
                    </div>
                    <button
                        className={'mc-button-secondary'}
                        disabled={formState.isSubmitting}
                        onClick={resetRecaptcha}>
                    </button>
                    <TrackVisibility offset={900}>
                        {({isVisible}) => isVisible && showCaptcha &&
                            <Recaptcha
                                ref={(e) => recaptchaInstance = e}
                                sitekey={constants.GOOGLE_SITE_KEY}
                                size="invisible"
                                verifyCallback={handleSubmit(onSubmit)}
                                onloadCallback={callback}
                            />}
                    </TrackVisibility>
                </form> : null}
            </div>
        </>
    )
}

export default Subscribe

