import React, {useEffect, useState} from "react"
import {Item} from './Item'
import disableAnimationForMobile from "../../helper/disableAnimationForMobile";

const CaseStudyGrid = ({isVisible, data, showButton, showNumberItems}) => {
    const [open, setOpen] = useState(false);
    const [startAnimation, setStartAnimation] = useState(false);

    const handleOpen = () => {
        setOpen(!open);
    };

    useEffect(()=>{
        // Disable animation for mobile
        let setIsVisible = disableAnimationForMobile(isVisible);

        if(setIsVisible) {
            window.setTimeout(() => {
                setStartAnimation(true);
            }, 100)
        }
    }, [isVisible]);
    const  items = data?.nodes ? data?.nodes : data;

    const setVisible = (showNumberItems, i) => (
        {
            display: !!showNumberItems ?
                ((i < showNumberItems) || (open)) ? 'block' : 'none' :
                'block'
        }
    )

    return (
        <>
            <div className={'case-study-grid flex-justify-sb'}>
                {!!items && items.map((page, i) => (
                    page && <Item
                        key={i}
                        setVisible={setVisible}
                        showNumberItems={showNumberItems}
                        i={i}
                        page={page}
                        startAnimation={startAnimation}
                    />
                ))}
            </div>
            {!!showButton && (
                <button onClick={handleOpen}
                        className={'transition-link paragraph-primary__bold possibilities-link mc-link mc-button-secondary right'}>
                    View all cases
                </button>
            )}
        </>
    )
}

export default CaseStudyGrid

