import React, {useEffect, useState} from "react";
import sanitizeHtml from 'sanitize-html';
import ServiceSubCategoriesItems from "./sub-category-items";
import sanitizeOptions from "../../helper/sanitizeOptions";
import TrackVisibility from 'react-on-screen';
import disableAnimationForMobile from "../../helper/disableAnimationForMobile";

const ServiceCategoriesItemsAnimation = ({isVisible, element}) => {
    const sanitizeOpt = sanitizeOptions();
    const [startAnimation, setStartAnimation] = useState(false);

    useEffect(() => {
        // Disable animation for mobile
        let setIsVisible = disableAnimationForMobile(isVisible);
        if (setIsVisible) {
            setStartAnimation(true);
        }
    }, [isVisible]);

    return (
        <li>
            <div
                className={'service-categories__content'}>
                <h4 className={'h4-title orange-icon-title'}>
                    <div
                        style={{
                            width: '100%',
                            overflow: 'hidden'
                        }}
                    >
                        <div
                            style={{
                                transition: 'all .75s ease .1s',
                                transform: `translateX(${startAnimation ? 0 : '-100%'})`
                            }}
                            className={'service-categories__content-title'}
                        >
                            {element.name}
                        </div>
                    </div>
                </h4>
                {!!element.description && (
                    <div
                        className={'service-categories__description'}
                        dangerouslySetInnerHTML={{__html: sanitizeHtml(element.description, sanitizeOpt.title)}}
                        style={{
                            transition: 'all .75s ease .3s',
                            opacity: startAnimation ? 1 : 0
                        }}
                    />
                )}
            </div>
            {Array.isArray(element?.services?.nodes)
            && !!element?.services?.nodes.length
            && (<div className={'service-categories__sub-list'}
                     style={{
                         transition: 'all .75s ease .2s',
                         opacity: startAnimation ? 1 : 0
                     }}
                >
                    <ul>
                        {element.services.nodes.map((element, item) => (
                            <ServiceSubCategoriesItems
                                key={`${element.id}-${item}`}
                                item={item}
                                element={element}
                            />
                        ))}
                    </ul>
                </div>
            )}
        </li>
    )
};

const ServiceCategoriesItems = ({element}) => {
    return (
        <TrackVisibility
            once
            offset={150}
            className={'lazyload-wrapper'}>
            <ServiceCategoriesItemsAnimation
                element={element}
            />
        </TrackVisibility>
    )
}

export default ServiceCategoriesItems;