import React from 'react';
import ServiceCategoriesItems from "./category-items";
import ServiceCategoriesTitle from './service-categories-title'
import TrackVisibility from 'react-on-screen';

const sortOrder = (a, b) => (a.serviceCategoryFields.sortOrder - b.serviceCategoryFields.sortOrder);

const ServiceCategoriesList = ({title, subTitle, serviceCategory}) => {

    return (
        <div className={'service-categories__wrapper'}>
            {Array.isArray(serviceCategory?.nodes) && (
                <>
                    <TrackVisibility once offset={50}>
                        <ServiceCategoriesTitle title={title}/>
                    </TrackVisibility>

                    <h3 className={'h3-title'}>
                        {subTitle}
                    </h3>
                    <ul className={'service-categories__list'}>
                        {serviceCategory.nodes.sort(sortOrder).map((element, item) => (
                                <ServiceCategoriesItems
                                    key={`${element.id}-${item}`}
                                    item={item}
                                    element={element}
                                />
                        ))}
                    </ul>
                </>
            )}
        </div>
    )
}

export default ServiceCategoriesList;