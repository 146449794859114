import React from "react";
import addGoogleCaptchaLink from "../helper/addGoogleCaptchaLink";

// Initialization of the Google captcha component

const SetCaptcha = () => {
    return (
        <>
            {addGoogleCaptchaLink()}
        </>
    );
};

export default SetCaptcha;