import React from "react"

export const OptionList = ({data, className, classNameUl, classNameli}) => (
    <ul className={classNameUl}>
        {data.nodes.map((el, i) => i < 1 && (
                <li key={el.id}
                    className={classNameli}>
                    <span className={`h5-title minor-title ${className}`}>
                        {el.name}
                    </span>
                </li>
            )
        )}
    </ul>
)