import React from "react";
import SuccessLogo from "../../assets/svg/thank.you.inline.svg";

const SuccessSubscribeMessage = ({handleEventPopup, status, message, eventPopup, loader}) => {
    const errorColor = '#FFF';

    return (
        <>
            {(status === 'mail_sent' || status === 'spam') && eventPopup && (
                <div className={`modal-inner-wrap`}>
                    <div className={'main-content-block'}>
                        <button
                            className={'close-popup'}
                            onClick={handleEventPopup}>
                            close
                        </button>
                        <div className={'thank-you-text'}>
                            {status === 'mail_sent' ?
                                <div className={'success-message'}>
                                    <p className={'header-image'}>
                                        <SuccessLogo/>
                                    </p>
                                    <p className={'header-text'}>
                                        You've become our subscriber
                                    </p>
                                    <p className={'text'}>
                                        We'll soon send you our email digest with ecommerce news & events. <br/>Please
                                        stay
                                        tuned!</p>
                                </div>
                                : status === 'spam' ? <div style={{color: errorColor}} className={'error-message'}>
                                    {message}
                                </div> : null}
                        </div>
                    </div>
                </div>
            )}
            {loader && (status !== 'mail_sent' || status !== 'spam') && (
                <div className={'loading-wrapper'}>
                    <span className={'loading'}/>
                </div>
            )}
        </>
    )
}

export default SuccessSubscribeMessage;
