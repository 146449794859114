import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout';
import ServiceCategoriesList from '../components/service-categories';
import TitleGrids from '../components/title-grids';
import TestimonialsHome from "../components/homepage/testimonials";
import { Helmet } from "react-helmet"
import LazyLoad from "react-lazyload";
import WhatWeDoHeader from "../components/service-categories/what-we-do-header";
import CTABlock from "../components/cta-block";

const WhatWeDo = () => {
    const { data, serviceCategory, caseStudies, homepage, testimonials } = useStaticQuery(graphql`
        {
            data: wpPage(slug: {eq: "what-we-do"}) {
                title
                uri
                whatWeDoLandingCustomFields {
                    title
                    titleContent
                    letsChatButton
                    howItHelps {
                        fieldGroupName
                        title
                        threeColumns
                        list {
                            description
                        }
                    }
                    categoriesBlock {
                        title
                        subTitle
                    }
                    taBlock1 {
                        fieldGroupName
                        title
                        buttonText
                        image {
                          mediaItemUrl
                          remoteFile {
                                childImageSharp {
                                    fluid(quality: 90) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                    taBlock2 {
                        fieldGroupName
                        title
                        buttonText
                        image {
                          mediaItemUrl
                          remoteFile {
                                childImageSharp {
                                    fluid(quality: 90) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
                caseStudiesTitleBlock {
                    block {
                        titleH2
                        titleH5
                        description
                        linkTarget
                        linkText
                    }
                }
                seo {
                    title
                    metaDesc
                    canonical
                }
                additionalMetaTag {
                    keywords
                    ogDescription
                    ogTitle
                    ogType
                    ogImage {
                        mediaItemUrl
                    }
                    twitterImage{
                        mediaItemUrl
                    }
                    twitterTitle
                    twitterDescription
                }
            }

            serviceCategory: allWpServiceCategory(
                sort: {fields: services___nodes___date, order: ASC}
            ) {
                nodes {
                    id
                    description
                    name
                    services {
                        nodes {
                            id
                            uri
                            title
                        }
                    }
                    serviceCategoryFields{
                        sortOrder
                    }
                }
            }

            caseStudies: allWpCaseStudy(
                limit: 6
                sort: { fields: date, order: DESC }
            ) {
                nodes {
                    id
                    slug
                    title
                    content
                    link
                    contentType {
                        node {
                            graphqlSingleName
                            name
                        }
                    }
                    caseStudySingleFeatured {
                        additionalFields {
                            titleH5
                            clientFigures
                        }
                    }
                    caseStudyRegions {
                        nodes {
                            id
                            name
                        }
                    }
                    caseStudyIndustries {
                        nodes {
                            id
                            name
                        }
                    }
                    caseStudyTechnologies {
                        nodes {
                            id
                            name
                        }
                    }
                    featuredImage {
                    node {
                        sourceUrl
                        remoteFile {
                        ...Thumbnail
                         }
                        }
                    }
                    info {
                        aboutClient
                        aboutClientShort
                        brand
                        result
                        gridImage {
                            mediaItemUrl
                            title
                            remoteFile {
                                childImageSharp {
                                    fluid(quality: 90) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }

            homepage: wpPage(isFrontPage: {in: true}){
                homepageCustomFields {
                    testimonialsBlock {
                        fieldGroupName
                        button
                        titleH5
                        titleH2
                    }
                }
            }

            testimonials: allWpTestimonial(
                sort: { fields: date, order: DESC }
            ) {
                nodes {
                    slug
                    title
                    id
                    testimonials {
                        title
                        authorName
                        authorPosition
                        testimonial
                        image {
                            remoteFile {
                                childImageSharp {
                                    fluid(quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                    testimonialsCustomFields {
                        caseStudy {
                            ... on WpCaseStudy {
                                id
                                uri
                            }
                        }
                        events {
                            ... on WpEvent {
                                id
                                uri
                            }
                        }
                    }
                    contentType {
                        node {
                            graphqlSingleName
                        }
                    }
                }
            }
        }
    `);

    const page = data.whatWeDoLandingCustomFields || {};
    const serviceCategoriesBlock = page.categoriesBlock || {};
    const ctaBlock1 = page.taBlock1 || {};
    const ctaBlock2 = page.taBlock2 || {};
    const caseStudyTitleBlock = data.caseStudiesTitleBlock.block || {};
    const letsChatBlockId = 'lets-chat';
    const { seo, additionalMetaTag, title } = data;
    // Set metadata
    const seoData = { ...{ seo }, additionalMetaTag, title };
    let caseStudyArray = Array.isArray(testimonials?.nodes) && testimonials?.nodes.filter((el) => (!el?.testimonialsCustomFields?.events));

    return (
        <Layout
            seoData={seoData}>
            <div className={'what-we-do'}>
                <Helmet
                    bodyAttributes={{
                        class: 'what-we-do-page-wrapper'
                    }}
                />
                <WhatWeDoHeader page={page}
                                letsChatBlockId={letsChatBlockId}/>
                {Array.isArray(serviceCategory?.nodes)
                && serviceCategory?.nodes.length
                && (
                    <div className={'page-content'}>
                        <ServiceCategoriesList
                            title={serviceCategoriesBlock.title}
                            subTitle={serviceCategoriesBlock.subTitle}
                            serviceCategory={serviceCategory}
                        />
                    </div>
                )}
                <CTABlock data={ctaBlock1}
                          letsChatBlockId={letsChatBlockId}
                />
                <div className={'page-content what-we-do__case-studies case-studies-block-container'}>
                    <TitleGrids data={{
                        customFields: 'case_studies_block',
                        titleH2: caseStudyTitleBlock.titleH2,
                        titleH5: caseStudyTitleBlock.titleH5,
                        description: caseStudyTitleBlock.description,
                        link: caseStudyTitleBlock.linkTarget,
                        button: caseStudyTitleBlock.linkText,
                        caseStudies: caseStudies,
                    }}/>
                </div>
                <CTABlock data={ctaBlock2}
                          letsChatBlockId={letsChatBlockId}
                />
                <div className={'page-content home'}>
                    <div className={'bottom-content'}>
                        <TestimonialsHome
                            testimonials={caseStudyArray}
                            homepage={homepage}/>
                    </div>
                </div>
            </div>
            {!!letsChatBlockId && (
                <LazyLoad classNamePrefix={letsChatBlockId}>
                    <div id={letsChatBlockId}/>
                </LazyLoad>
            )}
        </Layout>
    );
};

export default WhatWeDo;
